import { getScreenBreakpoint } from './constants'

export const getPx = (size: string | number) =>
	typeof size === 'number' ? size : getScreenBreakpoint(size)

export const smallerThan = (size: number) =>
	`@media(max-width: ${getPx(size)}px)`

export const mediaQueryBetween = (
	firstSizeExclusive: number,
	secondSizeInclusive: number
) => {
	const min = `min-width: ${getPx(firstSizeExclusive) + 1}px`
	const max = `max-width: ${getPx(secondSizeInclusive)}px`
	return `@media (${min}) and (${max})`
}

export const largerThan = (size: number) =>
	`@media(min-width: ${getPx(size) + 1}px)`

// These are the new exports for media queries. Rather than leaving it totally open to using smaller
// or larger than, or even between which can all cause confusion, lets just export the smaller than
// queries that people are used to at the exact breakpoints for emulation in chrome and its easy to
// recreate the larger than or between functionality in the rare case it is actually required
export const breakpoints = {
	desktop: 1440,
	laptop: 1024,
	tablet: 768,
	mobile: 320,
}
export const laptop: string = `@media(max-width: ${breakpoints.desktop - 1}px)`
export const tablet: string = `@media(max-width: ${breakpoints.laptop - 1}px)`
export const mobile: string = `@media(max-width: ${breakpoints.tablet - 1}px)`
