import { useState, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'

const useNetlifyGeo = () => {
	const [geo, setGeo] = useState()

	useEffect(() => {
		axios.get('/geo/default.json').then(res => {
			const redirectedGeo = _.get(res, 'data.geo')
			if (redirectedGeo) {
				setGeo(redirectedGeo)
			}
		})
	}, [])

	return geo
}

export default useNetlifyGeo
