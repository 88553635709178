import React from 'react'
import styled from 'styled-components'

import { lightBlue } from 'shared/colors'
import useGeoForMarketoForm from 'hooks/use-geo-for-marketo-form'
import { smallerThan } from 'shared/media-queries'

const StyledMarketoForm = styled.form`
	.mktoForm label {
		font-weight: normal !important;
	}
	.mktoField {
		width: 100% !important;
		border-radius: 2px !important;
		height: 40px !important;
	}

	.mktoForm .mktoAsterix {
		padding-left: 5px !important;
		padding-right: 0px !important;
	}

	.mktoFieldWrap.mktoRequiredField {
		width: 100% !important;
	}

	.mktoFieldWrap {
		width: 100% !important;
	}
	.mktoForm .mktoHtmlText.mktoHasWidth {
		width: 100% !important;
	}

	.mktoLabel.mktoHasWidth {
		width: 100% !important;
	}

	&.mktoForm {
		width: 100% !important;
	}

	.mktoForm .mktoOffset {
		height: 2px;
	}

	.mktoOffset {
		display: none;
	}

	.mktoForm .mktoGutter {
		height: 2px;
	}

	.mktoButtonWrap.mktoSimple {
		margin: 0 auto !important;
	}

	.mktoLabel.mktoHasWidth {
		font-size: 13px !important;
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		width: auto;
		margin-bottom: -11px !important;
		font-weight: normal !important;
		${smallerThan(480)} {
			margin-bottom: 0px !important;
		}
	}

	.mktoFormRow {
		width: 300px;
		display: flex;
		flex-direction: column;
	}

	&.mktoForm .mktoError .mktoErrorMsg {
		display: flex;
		width: 100%;
		max-width: 100%;
	}

	.mktoErrorDetail {
		margin-left: 2px;
	}

	.mktoAsterix {
		margin-left: 1px;
	}

	.mktoErrorArrowWrap {
		display: none;
	}

	.mktoErrorArrow {
		display: none;
	}

	.mktoError {
		right: unset;
		bottom: -25px;
		font-size: 10px;
	}

	.mktoFieldDescriptor.mktoFormCol {
		margin-bottom: 10px;
	}

	.mktoErrorMsg {
		color: red;
		margin-top: 10px;
		background: transparent;
		border: none;
		box-shadow: none;
		padding: 0;
		text-shadow: none;
		height: 24px;
	}

	.mktoField.mktoEmailField.mktoHasWidth.mktoRequired.mktoInvalid {
		border: 1px solid red;
	}

	.mktoField.mktoTextField.mktoHasWidth.mktoRequired.mktoInvalid {
		border: 1px solid red;
	}

	input.mktoField[type='checkbox'] {
		border: 2px solid black;
		width: 10px !important;
		height: 10px !important;
	}

	.mktoField.mktoEmailField.mktoHasWidth.mktoRequired {
		box-sizing: border-box;
		padding: 0 15px;
		height: 40px;
		font-size: 16px;
		font-weight: normal;
		line-height: 22px;
		border-radius: 1px;
		background-color: #f5f8fa;
		border: 1px solid #5e5e5e;
	}

	.mktoField.mktoHasWidth.mktoRequired {
		box-sizing: border-box;
		padding: 0 15px;
		height: 40px;
		font-size: 16px;
		font-weight: normal;
		line-height: 22px;
		color: #33475b;
		border-radius: 1px;
		background-color: #f5f8fa;
		border: 1px solid #5e5e5e;
	}

	.mktoField.mktoHasWidth {
		box-sizing: border-box;
		padding: 0 15px;
		height: 40px;
		font-size: 16px;
		font-weight: normal;
		line-height: 22px;
		color: #33475b;
		border-radius: 1px;
		background-color: #f5f8fa;
		border: 1px solid #5e5e5e;
	}

	.mktoLogicalField.mktoCheckboxList.mktoHasWidth {
		width: 100% !important;
	}

	.mktoLogicalField.mktoCheckboxList.mktoHasWidth.mktoValid input {
		background: black;
	}

	.mktoField.mktoTextField.mktoHasWidth.mktoRequired {
		box-sizing: border-box;
		padding: 0 15px;
		height: 40px;
		font-size: 16px;
		font-weight: normal;
		line-height: 22px;
		color: #33475b;
		border-radius: 1px;
		background-color: #f5f8fa;
		border: 1px solid #5e5e5e;
	}

	.mktoField.mktoTextField.mktoHasWidth {
		box-sizing: border-box;
		padding: 0 15px;
		height: 40px;
		font-size: 16px;
		font-weight: normal;
		line-height: 22px;
		color: #33475b;
		border-radius: 1px;
		background-color: #f5f8fa;
		border: 1px solid #5e5e5e;
	}
	.mktoField.mktoEmailField.mktoHasWidth.mktoRequired.mktoInvalid {
		box-sizing: border-box;
		padding: 0 15px;
		height: 40px;
		font-size: 16px;
		font-weight: normal;
		line-height: 22px;
		color: #33475b;
		border-radius: 1px;
		background-color: #f5f8fa;
		border: 1px solid #5e5e5e;
	}

	.mktoField.mktoTelField.mktoHasWidth.mktoRequired {
		box-sizing: border-box;
		padding: 0 15px;
		height: 40px;
		font-size: 16px;
		font-weight: normal;
		line-height: 22px;
		color: #33475b;
		border-radius: 1px;
		background-color: #f5f8fa;
		border: 1px solid #5e5e5e;
	}

	&.mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
		background: ${lightBlue};
		border-color: ${lightBlue};
		color: #ffffff;
		font-size: 14px;
		font-weight: bold;
		height: 45px;
		margin: 0 auto;
		cursor: pointer;
		display: inline-block;
		line-height: 14px;
		position: relative;
		text-align: center;
		border-radius: 1px;
		border-style: solid;
		border-width: 1px;
		width: 300px;
		margin-top: 15px;
		max-width: 300px;
		text-transform: none;
		:hover {
			background: ${lightBlue};
			border-color: ${lightBlue};
		}
		:active {
			background: ${lightBlue};
			border-color: ${lightBlue};
		}
		:visited {
			background: ${lightBlue};
			border-color: ${lightBlue};
		}
	}

	&.mktoForm .mktoRadioList > label,
	&.mktoForm .mktoCheckboxList > label {
		margin-top: -0.1em;
	}
`

const MarketoForm = ({ ...props }) => {
	useGeoForMarketoForm()
	return <StyledMarketoForm {...props} />
}

export default MarketoForm
