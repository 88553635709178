declare var MktoForms2: any

import { useEffect } from 'react'
import _ from 'lodash'

import useNetlifyGeo from './use-netlify-geo'
import isBrowser from 'shared/is-browser'

const useGeoForMarketoForm = () => {
	const geo = useNetlifyGeo()

	useEffect(() => {
		if (!isBrowser || !MktoForms2) {
			return
		}

		MktoForms2.whenReady(form => {
			if (geo) {
				if (geo !== 'other') {
					form.setValues({ Country: geo.toUpperCase() })
				}

				const $form = form.getFormElem()
				if (!$form || !$form[0]) {
					return
				}

				const formEl = $form[0]
				const formRows = [...formEl.getElementsByClassName('mktoFormRow')]
				const countryRow = formRows.find(el => el.innerText.includes('Country'))
				if (countryRow) {
					_.set(countryRow, 'style.display', 'none')
				}
			}
		})
	}, [geo])
}

export default useGeoForMarketoForm
