// Gray
export const gray02: string = '#fafafa'
export const gray20: string = '#cccccc'
export const gray50: string = '#b2b2b2'
export const gray60: string = '#9b9b9b'
export const gray65: string = '#595959'
export const gray80: string = '#333333'
export const black02: string = '#191919'

export const lightGray: string = '#7F7F7F'
export const grayBackground: string = '#F3F7F8'
export const grayText: string = '#4D4D4D'

// Blue
export const brandBlue: string = '#31708F'
export const lightBlue: string = '#32ADE6'
export const blue: string = '#1b88d1'
export const darkBlue: string = '#1D58BC'
export const navy: string = '#1D355E'

// Green
export const cyan: string = '#3DCEC6'
export const green: string = '#4AD37E'

// Orange
export const lightOrange: string = '#fe7842'
export const lightOrange2: string = '#f57c00'
export const orange: string = '#ffa33e'
export const darkOrange: string = '#ff6549'

// Red
export const brandDanger: string = '#D81159'

// White
export const white: string = '#FFFFFF'
