type Breakpoints = { [key: string]: number }

const breakpoints: Breakpoints = {
	'xx-small': 666,
	'x-small': 800,
	small: 1000,
	medium: 1240,
	large: 1600,

	// below are the new ones
	smallDesktop: 1439,
	tablet: 1027,
	mobile: 767,
}

export const getScreenBreakpoint = (size: string): number => {
	if (size in breakpoints) {
		return breakpoints[size]
	}
	throw new Error("Requested breakpoint doesn't exist")
}

export const sitePath =
	process.env.SITE_PATH || 'https://app.buildingconnected.com'

export const numberOfSubsOnBC = '1,000,000'
export const numberOfSubsOnBCWording = '1 million'
export const numberOfGCsOnBC = '4,000'
